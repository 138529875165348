import { useEffect, useState } from 'react';
import Logo from '../../../sharedComponents/Logo';
import './AdminLogin.css';
import { useDispatch, useSelector } from 'react-redux';
import { checkIsLoggedIn, register, sendAdminPassword } from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginAPI';
import { selectAdminLoading, selectAdminLogin } from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginSlice';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../sharedComponents/Loading/Loading';

const AdminLogin = () => {
    const [sectionPos, setSectionPos] = useState(false);
    const [loginInputs, setLoginInputs] = useState({ name: '', password: '' });
    const [matchError, setMatchError] = useState(false)
    const [passError, setPassError] = useState(false)
    const [regInputs, setRegInputs] = useState(
        {
            apiKey: '',
            clientId: '',
            parkId: '',
            idramId: '',
            idramApiKey: '',
            username: '',
            password: '',
            repeatPassword: ''
        }
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Select the admin login state and loading from Redux
    const adminLogin = useSelector(selectAdminLogin);  // Use this if you need to access other login state data
    const loading = useSelector(selectAdminLoading); // Assuming you have a loading property

    const handlePos = (payload) => {
        setSectionPos(payload);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;


        setLoginInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(sendAdminPassword({ username: loginInputs.name, password: loginInputs.password }));

    };
    const handleRegInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'repeatPassword' && value !== regInputs.password) {
            setMatchError(true)
        } else setMatchError(false)


        if (name === 'password' && value.length < 5 && value.length !== 0) {
            setPassError(true)
        } else setPassError(false)


        setRegInputs({
            ...regInputs,
            [name]: value
        });
    };
    const handleRegSubmit = (e) => {
        e.preventDefault()
        const {
            apiKey,
            clientId,
            parkId,
            idramApiKey,
            idramId,
            username,
            password,
            repeatPassword
        } = regInputs;
        if (!matchError && !passError && password.length > 0) {
            dispatch(register({
                username: username,
                password: password,
                park_id: parkId,
                client_id: clientId,
                yandex_key: apiKey,
                idram: idramId,
                idram_key: idramApiKey
            } ))
            setRegInputs(     {
                apiKey: '',
                clientId: '',
                parkId: '',
                idramId: '',
                idramApiKey: '',
                username: '',
                password: '',
                repeatPassword: ''
            })
        } else {
            console.log('fail');
        }
    };

    useEffect(() => {
        dispatch(checkIsLoggedIn())
    }, []);
    useEffect(() => {
        if (!loading) {
            if (!adminLogin) {
                navigate('/admin/login');  // Redirect to login if not logged in
            } else {
                navigate('/admin/dashboard');  // Stay on dashboard if logged in
            }
        }
    }, [loading, adminLogin]);

    if (loading) {
        return <Loading show={true} />;
    }


    return (
        <div className='AdminLogin'>
            <div className="AdminLoginSection">
                <div className="LoginSection regSection">
                    <form className='LoginSectionForm' onSubmit={handleRegSubmit}>
                        <input
                            type="text"
                            name="apiKey"
                            placeholder='API-KEY'
                            value={regInputs.apiKey}
                            onChange={handleRegInputChange}
                            required
                        />
                        <input
                            type="text"
                            name="clientId"
                            placeholder='Client-Id'
                            value={regInputs.clientId}
                            onChange={handleRegInputChange}
                            required
                        />
                        <input
                            type="text"
                            name="parkId"
                            placeholder='Park-Id'
                            value={regInputs.parkId}
                            onChange={handleRegInputChange}
                            required
                        />
                        <input
                            type="text"
                            name="idramApiKey"
                            placeholder='Idram API-KEY'
                            value={regInputs.idramApiKey}
                            onChange={handleRegInputChange}
                            required
                        />
                        <input
                            type="text"
                            name="idramId"
                            placeholder='Idram Id'
                            value={regInputs.idramId}
                            onChange={handleRegInputChange}
                            required
                        />
                        <input
                            type="text"
                            name="username"
                            placeholder='Ծածկանուն'
                            value={regInputs.username}
                            onChange={handleRegInputChange}
                            required
                        />
                        <span className={passError ? 'inputError matchError' : 'inputError'}>Ծածկագիրը պետք է լինի առնվազն 5 նիշ</span>
                        <input
                            type="password"
                            name="password"
                            placeholder='Ծածկագիր'
                            value={regInputs.password}
                            onChange={handleRegInputChange}
                            required
                        />
                        <span className={matchError ? 'inputError matchError' : 'inputError'}>ծածկագրերը չեն համընկնում</span>
                        <input
                            type="password"
                            name="repeatPassword"
                            placeholder='Կրկնել ծածկագիրը'
                            value={regInputs.repeatPassword}
                            onChange={handleRegInputChange}
                            required
                        />
                        <button className='LoginSubmitBtn'>Մուտք</button>
                    </form>

                </div>
                <div className={sectionPos ? 'DiamondPaySection DiamondPaySectionRight' : 'DiamondPaySection'}>
                    <div className="DiamondPayBtnSection">
                        <button className={sectionPos ? "AdminLogRegBtn AdminLogRegBtnActive" : 'AdminLogRegBtn'} onClick={() => handlePos(true)}>Գրանցվել</button>
                        <button className={sectionPos ? "AdminLogRegBtn" : 'AdminLogRegBtn AdminLogRegBtnActive'} onClick={() => handlePos(false)}>Մուտք</button>
                    </div>
                    <div className="DiamondPayLogoSection">
                        <div className="DiamondPayLogoContainer">
                            <Logo size='45px' color='#FF5C00' />
                            <h1>Diamond Pay</h1>
                        </div>
                        <h2>Բարի Գալուստ</h2>
                    </div>
                </div>
                <div className="LoginSection">
                    <div className="MobileLogo">
                        <Logo size='45px' color='#FF5C00'/>
                        <h3>Բարի գալուստ</h3>
                    </div>
                    <h3>Մուտք գործեք Diamond Pay</h3>
                    <form className='LoginSectionForm' onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="name"
                            placeholder='Ծածկանուն'
                            value={loginInputs.name}
                            onChange={handleInputChange}
                        />
                        <input
                            type="password"
                            name="password"
                            placeholder='Ծածկագիր'
                            value={loginInputs.password}
                            onChange={handleInputChange}
                        />
                        <button type="submit" className='LoginSubmitBtn'>Մուտք</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AdminLogin;

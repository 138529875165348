import './AdminSettings.css'
import {Switch} from "antd";
import {useState} from "react";

const AdminSettings = () => {
    const [settings, setSettings] = useState({
        bonus: false,
    })
    const onChange = (checked) => {
        setSettings(checked)
    }
    return (
        <div className="AdminSettings">
            <div className="AdminSettingsHeader">
                <h2>Կարգավորումներ</h2>
                <div className="AdminSettingsContainer">
                    <div className="AdminSettingsItem">
                        <h3>Բոնուսների կանխիկացման կանխում</h3>
                        <div className="AdminSettingsItemContent">
                            <p>Վարորդներն չթույլատրել բոնուսների և Idram/Easypay/Tellcel ի
                                կամ այլ միջոցներով փոխանցված գումարը կանխիկացնել
                            </p>
                            <Switch value={settings.bonus} onChange={onChange} sx={{
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                    color: '#4caf50',  // Thumb color when checked
                                },
                                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: '#4caf50', // Track color when checked
                                },
                                '& .MuiSwitch-switchBase': {
                                    color: '#f44336', // Thumb color when unchecked
                                },
                                '& .MuiSwitch-track': {
                                    backgroundColor: '#f44336', // Track color when unchecked
                                },
                            }}/>
                        </div>
                    </div>
                </div>
            </div>
            <button className='AdminSettingsApplyBtn'>Հաստատել</button>
        </div>
    )
}

export default AdminSettings;
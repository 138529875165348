import { useDispatch, useSelector } from "react-redux";
import { fetchQueryDrivers, sendAdminTransfer } from "../../../store/slices/adminSlices/adminTransferSlice/adminTransferAPI";
import "./Transfers.css"
import { useEffect, useState } from 'react'
import { selectDriverList } from "../../../store/slices/adminSlices/adminDriverList/adminDriverList";
import { checkIsLoggedIn } from "../../../store/slices/adminSlices/adminLoginSlice/adminLoginAPI";
import { selectAdminLoading, selectAdminLogin } from "../../../store/slices/adminSlices/adminLoginSlice/adminLoginSlice";
import { useNavigate } from "react-router-dom";
import { clearDrivers, selectAdminTransfer, selectAdminTransferStatus } from "../../../store/slices/adminSlices/adminTransferSlice/adminTransferSlice";
import { toast } from "react-toastify";


const Transfers = () => {
    const dispatch = useDispatch()
    const adminLogin = useSelector(selectAdminLogin)
    const status = useSelector(selectAdminTransferStatus)
    const loading = useSelector(selectAdminLoading)
    const navigate = useNavigate()
    let driverId = ''
    let driver 
    const driversData = useSelector(selectAdminTransfer)
    const [inputs, setInputs] = useState({
        driver: '',
        amount: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs({
            ...inputs,
            [name]: value
        });
    };
    const handleDriverName = (name) => {
        setInputs({
            ...inputs,
            driver: name
        });
    }
    const handleTransfer = (e) => {
        e.preventDefault()
        driver = driversData.data.drivers.find(el => el.name === inputs.driver)
        driverId = driversData.data.drivers.find(el => el.name === inputs.driver).driver_id
        if (inputs.amount <= driver.balance) {
            dispatch(sendAdminTransfer({ amount : inputs.amount, driver_id: driverId, park_id: adminLogin.park_id }))
            setInputs({
                driver: '',
                amount: ''
            })
        }else {
            toast.error('Անբավարար միջոցներ')
        }
        
        }
    useEffect(() => {
        if (inputs.driver.length >= 4 && inputs.driver !== '+374') {
            dispatch(fetchQueryDrivers({ park_id: adminLogin?.park_id, limit: 10, offset: 0, query: inputs.driver }))
        } else if (!inputs.driver || inputs.driver === '+374') {
            dispatch(clearDrivers())
        }
    }, [inputs.driver,adminLogin]);
    useEffect(() => {
        dispatch(checkIsLoggedIn())
    }, []);
    useEffect(() => {
        if(!loading){
            if (!adminLogin) {
                navigate('/admin/login');  // Redirect to login if not logged in
            } else {
                navigate('/admin/transfers');  // Stay on dashboard if logged in
            }
        }
    }, [loading]);
    
    useEffect(() => {
            if (status === 'success') {
                toast.success('Փոխանցումը կատարված է')
            }
    }, [status]);
    return (
        <div className='Transfers'>

            <div className="form">
                <span className='TransfersHeading'>Փոխանցել Իդրամին</span>
                <form >
                    <div className="inputContainer">
                        <input
                            type="text"
                            onChange={handleChange}
                            value={inputs.driver}
                            name="driver"
                            autocomplete="off"
                        />
                        <div className="queryData">
                            <div className="queryDataArrow">

                            </div>
                            {
                                    driversData?.data?.drivers[0]?.name !== inputs.driver && driversData?.data?.drivers.map(el => (
                                    <span onClick={() => handleDriverName(el.name)} key={el.name}>{el.name}</span>
                                ))
                            }
                        </div>
                        <span className={inputs.driver ? 'filled floatingPlaceholder' : 'floatingPlaceholder'}>Վարորդ, հեռ.ամար, IDram</span>
                    </div>
                    <div className="inputContainer">
                        <input
                            type="text"
                            onChange={handleChange}
                            name="amount"
                            value={inputs.amount}
                        />
                        <span className={inputs.amount ? 'filled floatingPlaceholder' : 'floatingPlaceholder'}>Գումար</span>
                    </div>
                    <button onClick={(e) => handleTransfer(e)}>Ուղարկել</button>
                </form>
            </div>
        </div>
    );
}

export default Transfers;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Transfer.css';
import { sendDriverTransfer } from '../../../store/slices/driverTransferSlice/driverTransferAPI';
import { selectDriver } from '../../../store/slices/deiverSlice/deiverSlice';
import { selectLanguage } from '../../../store/slices/languageSlice/LanguageSlice';
import { languageProvider } from '../../../assets/languageProvider';
import { selectDriverTransfer } from '../../../store/slices/driverTransferSlice/driverTransferSlice';
import {toast} from "react-toastify";

const Transfer = ({setNewAmount, setAnim}) => {
    const dispatch = useDispatch();
    const [amount, setAmount] = useState('');
    const [count, setCount] = useState(0);

    const driver = useSelector(selectDriver)
    const driver_id = driver?.data?.driver_id
    const park_id = driver?.data?.park_id
    const activeLanguage = useSelector(selectLanguage)
    const language = languageProvider({page: 'main', lang: activeLanguage})
    const driverTransfer = useSelector(selectDriverTransfer)

    const handleTransfer = () => {
        if (amount) {
            if (count === 0) {
                dispatch(sendDriverTransfer({amount, driver_id,park_id})); 
                setAmount(''); 
                startCountdown(20, setCount)  
                setAnim(true)
                
                setTimeout(() => {
                    setAnim(false)
                }, 2000);
            }
        }
    };
    useEffect(() => {
        setNewAmount(driverTransfer?.amount)
        if (driverTransfer?.status === 'ok'){
            toast.success(`${driverTransfer?.amount} դրամը փոխանցված է`, {
                autoClose: 2000,
            } );
        }
    }, [driverTransfer]);
    const startCountdown = (start, updateState) => {
        let count = start;
      
        const timer = setInterval(() => {
          count -= 1;
          updateState(count); 
      
          if (count <= 0) {
            clearInterval(timer); 
          }
        }, 1000);
      };
      const [displayCount, setDisplayCount] = useState('')
      useEffect(() => {
        if(count < 10 ){
            setDisplayCount(`0${count}`)
        }else{
            setDisplayCount(`${count}`)
            
        }
      }, [count]);
      useEffect(() => {
        if(count === 0){
            setDisplayCount('')
        }
      }, [])
    return (
        <div className='Transfer'>
            <div className="TransferHeading">
                <span>{language.sendIdram}</span>
            </div>
            <div className="TransferContainer">
                <div className="balance">
                    <input 
                        type="number" 
                        className='balanceInput' 
                        max='999999' 
                        min='0' 
                        value={amount} 
                        onChange={(e) => setAmount(e.target.value)} 
                    />
                    <span>֏</span>
                </div>
                <div className="transferButton">
                    <button onClick={handleTransfer}>{count ? `00:${displayCount}`:  language.send}</button>
                </div>
            </div>
        </div>
    );
}

export default Transfer;

import "./DriverPassword.css"

import { selectLoginInput } from '../../../store/slices/loginInputSlice/loginInputSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguage } from '../../../store/slices/languageSlice/LanguageSlice';
import { sendPassword } from '../../../store/slices/loginInputSlice/loginInputAPI';
import { useState } from "react";
import { languageProvider } from "../../../assets/languageProvider";


const DriverPassword = ({ value, onChange }) => {
    const [passInput, setPassInput] = useState('')
    const dispatch = useDispatch()
    const loginInput = useSelector(selectLoginInput)
    const error = loginInput.error
    const activeLanguage = useSelector(selectLanguage)
    const language = languageProvider({ page: 'login', lang: activeLanguage })


    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(sendPassword({ username: loginInput.phoneNumber, password: passInput }))
    }
    const handlePassInput = (value) => {
        setPassInput(value)
    }

    return (
        <>
            <span>
                {language.hintPassword}
            </span>
            <form className='LoginForm' onSubmit={handleSubmit}>
                <input
                    className={error === 'wrong pass' ? 'wrongpass' : ''}
                    type="password"
                    value={passInput}
                    onChange={(e) => handlePassInput(e.target.value)}
                />
                <button type="submit">
                    {language.login}
                </button>
            </form>
        </>
    )
}

export default DriverPassword

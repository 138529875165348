import React, { useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import Logo from '../../../sharedComponents/Logo';
import { useMediaQuery } from '@mui/material';

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showSidebar, setShowSidebar] = useState(false);
  const isSmall = useMediaQuery('(max-width:900px)')
  const logoClick = () => {
    navigate('dashboard');
  };

  const handleSidebar = () => {
    if (isSmall) {
      setShowSidebar(!showSidebar);
    }
  };

  return (
    <>
      <div className={showSidebar ? 'Sidebar showSidebar' : 'Sidebar'}>
        <div onClick={logoClick} className="SidebarHeading">
          <Logo color="#FF5C00" />
          <h1>Diamond Pay</h1>
        </div>
        <div className="SidebarCategories">
          <h3>Գլխավոր</h3>
          <ul>
            <NavLink to="dashboard" onClick={handleSidebar} className={location.pathname === '/admin' ? 'active Navlink' : 'Navlink'}>
              <li><i className="fa-solid fa-chart-line"></i>Հաշվետվություն</li>
            </NavLink>
            <NavLink to="transactions" onClick={handleSidebar} className="Navlink">
              <li><i className="fa-solid fa-money-bill-transfer"></i>Գործարքներ</li>
            </NavLink>
            <NavLink to="transfers" onClick={handleSidebar} className="Navlink">
              <li><i className="fa-solid fa-right-left"></i>Փոխանցում</li>
            </NavLink>
            <NavLink to="drivers" onClick={handleSidebar} className="Navlink">
              <li><i className="fa-solid fa-users"></i>Վարորդներ</li>
            </NavLink>
          </ul>
          <div className="SidebarSettings">
            <NavLink to={'settings'} onClick={handleSidebar} className={"Navlink"}>
              <span><i className="fa-solid fa-gears"></i>Կարգավորումներ</span>
            </NavLink>
          </div>
        </div>
      </div>
      {showSidebar && <div className="DimmedOverlay" onClick={handleSidebar}></div>}
      {!showSidebar && (
        <button onClick={handleSidebar} className="SidebarShowBtn">
          <i className="fas fa-bars"></i>
        </button>
      )}
    </>
  );
};

export default Sidebar;

/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from 'react-router-dom';
import './MainInfo.css';
import { useEffect } from 'react';
import { fetchDriver } from '../../../store/slices/deiverSlice/deiverAPI';
import { selectDriver } from '../../../store/slices/deiverSlice/deiverSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectDriversOrders } from '../../../store/slices/driverOrdersSlice/driverOrdersSlice';
import { fetchDriverOrders } from '../../../store/slices/driverOrdersSlice/driverOrdersAPI';
import { sendDriverTransfer } from '../../../store/slices/driverTransferSlice/driverTransferAPI.js';
import { selectLoginInput } from '../../../store/slices/loginInputSlice/loginInputSlice.js';
import { selectLanguage } from '../../../store/slices/languageSlice/LanguageSlice.js';
import { languageProvider } from '../../../assets/languageProvider.js';
import CircularProgress from '../../../sharedComponents/CircularProgres/CircularProgres.js';

const MainInfo = ({newAmount, anim}) => {
    const dispatch = useDispatch();
    const driver = useSelector(selectDriver);
    const phoneNumber = useSelector(selectLoginInput);
    const driverOrders = useSelector(selectDriversOrders);
    const activeLanguage = useSelector(selectLanguage)
    const language = languageProvider({page: 'main', lang: activeLanguage})
    useEffect(() => {
        dispatch(sendDriverTransfer());
        if (!driver?.data && driver?.errorMessage !== 'Something went wrong') {
            dispatch(fetchDriver(phoneNumber.phoneNumber));
        }
    }, [driver, phoneNumber]);

    useEffect(() => {
        if (driver?.data) {
            dispatch(fetchDriverOrders(driver.data.driver_id));
        }
    }, [driver]);
 


    const successPercent = driverOrders?.total
        ? Math.round((driverOrders.ok_total / driverOrders.total) * 100)
        : 0;
    const unSuccessMoney =driverOrders?.total
    ? driverOrders?.total - driverOrders?.ok_total
    : 0;
    const balance = driver?.data?.balance
    ? driver?.data?.balance
    :" 0.000";


    let amount = 0
    if ((newAmount || newAmount === 0) && newAmount <= balance) {
        amount = balance - newAmount
        
    }else{
        amount = balance
    }
    

    return (
        <div className='MainInfo'>
            <div className="MainInfoHeader">
                <span id='MainInfoHeading'>{language.balnace}</span>
                <span className={anim? 'MainInfoMoney anim ' : 'MainInfoMoney'}>֏{amount.toFixed(2)}</span>
            </div>  
            <CircularProgress percent={!driver?.loading && !driver?.rejected ? successPercent : 0} />
            <div className="SeeMoreContainer">
                <Link className='SeeMore' to='/orders'>{language.seeOrders}</Link>
            </div>
            <div className="MainInfoSecondary">
                <div className="MainInfoSent">
                    <span>{language.sent}</span>
                    <span className='SecondarySpan'>֏{driverOrders?.ok_total||0}</span>
                </div>
                <div className="MainInfoNotSent">
                    <span>{language.canceled}</span>
                    <span className='SecondarySpan'>֏{unSuccessMoney }</span>
                </div>
            </div>
        </div>
    );
};

export default MainInfo;

import { createSlice } from "@reduxjs/toolkit";
import { adminLogout, checkIsLoggedIn, register, sendAdminPassword } from "./adminLoginAPI";

const adminLoginSlice = createSlice({
    name: 'adminLogin',
    initialState: {
        loading: true,
        data: null
    },
    reducers:{},
    extraReducers: (builder) => {
        builder
            .addCase(sendAdminPassword.fulfilled, (state, {payload}) => {
                return {
                    loading: false,
                    data: {
                        ...payload
                    }
                }
            })
            .addCase(adminLogout.fulfilled, (state, {payload})=>{
                return {
                    loading: false,
                    data: null
                }
            })
            .addCase(checkIsLoggedIn.fulfilled,(state, {payload})=>{
                return {
                    loading: false,
                    data: {
                        ...payload
                    }
                }
            })
            .addCase(checkIsLoggedIn.pending,(state, {payload})=>{
                return {
                    loading: true,
                    data: null
                }
            })
            
            .addCase(checkIsLoggedIn.rejected, (state, {payload})=>{
                return {
                    ...state,
                    loading: false
                }
            })
            .addCase(register.fulfilled, (state, {payload})=>{
                console.log('successSLICE');
            })
    },
});
export const selectAdminLogin = (state) => state.adminLogin.data
export const selectAdminLoading = (state) => state.adminLogin.loading
export const adminLoginReducer = adminLoginSlice.reducer;

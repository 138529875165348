import "./DriverRegister.css"

import { selectLoginInput, setLogin, setLoginInput } from '../../../store/slices/loginInputSlice/loginInputSlice';
import LanguageChange from '../../../sharedComponents/LanguageChange';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDriver } from '../../../store/slices/deiverSlice/deiverAPI';
import { selectDriver } from '../../../store/slices/deiverSlice/deiverSlice';
import { selectLanguage } from '../../../store/slices/languageSlice/LanguageSlice';
import { checkDriver, registerDriver } from '../../../store/slices/loginInputSlice/loginInputAPI';
import { useState } from "react";
import { languageProvider } from "../../../assets/languageProvider";
   
    
const DriverRegister = () => {
    const [regInput, setRegInput] = useState('')
    const dispatch = useDispatch()
    const loginInput = useSelector(selectLoginInput)
    const activeLanguage = useSelector(selectLanguage)
    const language = languageProvider({page: 'login', lang: activeLanguage})

    const handleSubmit = (e) =>{
        e.preventDefault()
        if (regInput.trim().length !== 0) {
            dispatch(registerDriver({phone:loginInput.phoneNumber, password: regInput}));

            setTimeout(() => {
                window.location.reload()
            }, 1000);
        }
    }
    const handleRegInput = (value) =>{
        setRegInput(value)
    }
    
    return (
        <>
        <span>
            {language.hintReg}
        </span>
        <form className='LoginForm' onSubmit={handleSubmit}>
            <input 
                type="password" 
                value={regInput} 
                onChange={(e) => handleRegInput(e.target.value)} 
            />
            <button type="submit">
                {language.reg}
            </button>
        </form>
    </>
    )
}

export default DriverRegister

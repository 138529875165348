import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../axiosInstance';


export const fetchDashboard = createAsyncThunk(
    'dashboard/fetchDashboard',
    async function({park_id, start, end, driver_id, status}) {
        // Default start to the first day of the current month if not provided

        // Construct the params object conditionally
        const params = {
            park_id,
            start,
            ...(end && { end }), // Include `end` if truthy
            ...(driver_id && { driver_id }), // Include `driver_id` if truthy
            ...(status && status.length > 0 && { status }) // Include `status` if array has values
        };
        
        // Make the API request
        const response = await axios.get('https://api.diamondpay.am/api/park/report', {
            params,
            headers: {
                'Content-Type': 'application/json',
            }
        });

        return response.data;
    }
);
